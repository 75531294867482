/* mobile */
@media (max-width: 640px) {
    .basket {
        width: 100vw;
    }

    .basket__header {
        height: 56px;
    }

    div.product-in-basket__card-image {
        width: 80px;
    }

    p.product-in-basket__card-name,
    p.product-in-basket__card-price,
    p.basket__price {
        font-size: 14px;
    }

    p.product-in-basket__card-color {
        font-size: 12px;
    }

    p.basket__price {
        font-size: 18px;
    }

}

/* tablet */
@media (min-width:641px) and (max-width: 1024px) {
    .basket {
        width: 50vw;
    }

    div.product-in-basket__card-image {
        width: 120px;
    }

    p.product-in-basket__card-name,
    p.product-in-basket__card-price,
    p.basket__price {
        font-size: 21px;
    }

    p.product-in-basket__card-color {
        font-size: 16px;
    }


}

/* pc */
@media (min-width: 1025px) {
    .basket {
        width: 30vw;
    }

    div.product-in-basket__card-image {
        width: 100px;
    }

    p.product-in-basket__card-name,
    p.product-in-basket__card-price,
    p.basket__price {
        font-size: 14px;
    }

    p.product-in-basket__card-color {
        font-size: 12px;
    }
}

div.product-in-basket__card-image {
    margin: 5px 0;
}

.basket__header {
    background-color: black;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 15px;
    box-sizing: border-box;
    position: fixed;
    z-index: 10;
}



h4.basket__title {
    font-size: 28px;
    font-weight: 500;
}

.basket__products {}

.basket__order {
    /* margin: 50px 0 0 0; */
    /* position: absolute; */
    /* bottom: 0; */
}

.product-in-basket {
    width: 100%;
    display: flex;
    align-items: center;
    align-items: flex-start;
}

div.product-in-basket {
    gap: 15px;
}


.basket {
    height: 100vh;
}

div.basket__content {
    position: relative;
    top: 70px;
    height: 90%;
    display: flex;
    flex-direction: column;
}


div.basket__cashback {
    align-self: flex-end;
}


button.basket__button {
    width: 100%;
}



p.product-in-basket__card-color {
    color: grey;
}

p.product-in-basket__card-price {
    font-weight: 600;
}

p.basket__price {
    font-weight: 600;
    text-transform: uppercase;
    /* font-size: 24px; */
}

button.product-in-basket__card-quantity {
    font-weight: 700;
    width: 25px;
}

div.product-in-basket__card-counter {
    --ButtonGroup-separatorColor: none;
    margin: 5px 0 0 0;
}

p.product-in-basket__card-name {
    font-weight: 600;
}