/* mobile */
@media (max-width: 640px) {

    nav.product-page__breadrumbs,
    .product-page__breadrumbs p {
        font-size: 14px;
    }

    h5.product-page__name,
    h5.product-page__price {
        font-size: x-large;
        font-weight: 500;
    }

    .product-page__content {
        flex-direction: column;
        width: 100%;
        gap: 5px;
    }

    button.product-page__button {
        width: -webkit-fill-available;
        font-size: small;
    }
}


/* tablet  mobile*/
@media (max-width: 1024px) {
    .product-page {
        width: 100vw;
    }


    button.product-page__quantity {
        font-size: 15px;
        font-weight: 600;
    }

    h5.product-page__name,
    h5.product-page__price {
        font-size: x-large;
        font-weight: 500;
    }

}

/* tablet */
@media (min-width:641px) and (max-width: 1024px) {
    .product-page__content {
        flex-direction: row;
        gap: 20px;
    }

    .product-page__gallery {
        width: 50%;
    }

    nav.product-page__breadrumbs,
    .product-page__breadrumbs p {
        font-size: 24px;
    }

    .product-page__info__box {
        width: 60%;
    }
}

/* pc */
@media (min-width: 1025px) {
    .product-page {
        width: 80vw;
    }

    .product-page__content {
        flex-direction: row;
        gap: 40px;
    }

    .product-page__gallery {
        width: 40%;
    }

    button.product-page__button {
        width: 300px;
    }

    .product-page__content button {
        font-size: 16px;
        font-weight: 600;
    }

    nav.product-page__breadrumbs,
    .product-page__breadrumbs p {
        font-size: 20px;

    }


    .product-page__info__box {
        width: 60%;
    }

}



.product-page__content {
    display: flex;
}

.product-page__breadrumb {
    &:hover {
        color: #700b03;
    }
}


nav.product-page__breadrumbs {
    margin: 20px 0;
}



.product-page__content p {
    color: grey;
    font-size: 18px;
}


.swiper {
    border-radius: 1rem;
    width: 100%;
    height: 100%;
}



.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
}



.swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    background-size: cover;
    background-position: center;
}

.mySwiper2 {
    height: 80%;
    width: 100%;
}

.mySwiper {
    height: 20%;
    box-sizing: border-box;
    margin: 10px 0;
}

.mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.5;
    overflow: hidden;
}

.mySwiper .swiper-slide-thumb-active {
    opacity: 1;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

div.product-page__counter {
    --ButtonGroup-separatorColor: none
}