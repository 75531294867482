@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Geologica:wght@100..900&family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap');


body {
    margin: 0;
    background-color: #F6F6F6;

}

*:not(p.homepage__quote) {
    font-family: "Geologica", sans-serif !important;

}


body::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.link {
    color: inherit;
    text-decoration: none;
}

.fgp_pswrd {
    width: 100%;
    text-align: center;

    &:hover {
        color: #113d69;
    }
}

h5.title {
    margin: 15px 0;
    text-align: center;
    font-weight: 700;
}

h5.subtitle {
    font-weight: 500;
    margin: 15px 0;
}

button.mainbutton {
    margin: 25px 0;
}

/* mobile */
@media (max-width: 640px) {
    button.mainbutton {
        width: inherit;
    }

    .authpage__main h5.title,
    .accountpage h5.title {
        font-size: 30px;
        font-weight: 600;
    }

    h5.subtitle {
        font-size: 20px;
    }
}

/* tablet */
@media (min-width:641px) and (max-width: 1024px) {
    button.mainbutton {
        font-size: larger;
        width: inherit;
    }

    h5.title {
        font-size: 34px;
    }

    .authpage__main h5.title,
    .accountpage h5.title {
        font-weight: 600;
    }
}

/* pc */
@media (min-width: 1025px) {
    button.mainbutton {
        font-size: medium;
        width: inherit;
    }

    h5.title {
        font-size: 32px;
    }

    .authpage__main h5.title,
    .accountpage h5.title {
        font-weight: 600;
    }
}