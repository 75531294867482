/* mobile + tablet */
@media (max-width: 1024px) {
    .header__icon.pc {
        display: none;
    }

    div.header__menu {
        display: none;
    }

    h5.header__logo {
        justify-content: flex-start;
        height: 60px;
        margin-left: 65px;
        font-weight: 800;
    }
}

/* mobile */
@media (max-width: 640px) {
    h5.header__logo {
        font-size: 22px;
    }
}

/* pc */
@media (min-width: 1025px) {
    .header__icon.mb {
        display: none;
    }

    .header__icon.pc {
        margin: 0 10px;
    }


    h5.header__logo {
        width: 100%;
        height: 64px;

        justify-content: center;
    }

}


svg.header__icon {
    font-size: 35px;
}

a.header_account-icon {
    height: 35px;
}

h5.header__logo {
    display: flex;
    font-weight: 800;
    align-items: center;
    position: absolute;
    z-index: 998;
}