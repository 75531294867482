/* mobile */
@media (max-width: 640px) {
    a.link.typeItem {
        font-size: 24px;
    }

    h3.catalogue__title {
        font-size: 38px;
    }
}

/* pc */
@media (min-width: 1025px) {}

/* tablet */
@media (min-width:641px) and (max-width: 1024px) {
    h3.catalogue__title {
        font-size: 44px;
    }

    a.link.typeItem {
        font-size: 32px;
    }
}

.catalogue {
    margin-top: 20px;
}

h3.catalogue__title {
    text-transform: uppercase;
    font-weight: 600
}

a.link.typeItem {
    text-transform: lowercase;
    font-weight: 500
}