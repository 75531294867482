.accountpage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.accountpage__profile-cards {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 30px;
}

.accountpage__profile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.accountpage__wishlist {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.accountpage__wishlist-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 15px;
    /* align-items: flex-start; */
    justify-content: center;
}

.accountpage__greeting {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

div.accountpage__menu {
    flex-direction: column;
}



button.accountpage__button {
    margin-top: 25px;
}

div.accountpage__tabpanel {
    width: 100vw;
    box-sizing: border-box;
}

h5.accountpage__wishlist-title {
    font-weight: 600;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

p.accountpage__wishlist-price {
    font-weight: 500;
    font-size: 18px;

}

p.accountpage__wishlist-color {
    color: grey;
}


/* mobile */
@media (max-width: 640px) {
    div.accountpage__tablist {
        width: 90vw;
        font-size: 18px;

    }

    .accountpage__profile-cards {
        flex-direction: column;
    }

    .accountpage__card {
        width: 80vw;
    }

    .accountpage__greeting {
        flex-direction: column;
    }

    div.accountpage__input {
        --Input-minHeight: 45px;
    }

    .accountpage label {
        font-size: 20px;
    }

    h5.accountpage__profile_title {
        font-size: 20px;
        font-weight: 600;
    }

    button.accountpage__button {
        font-size: 16px;
    }

    .accountpage__wishlist-container {
        width: 100%;
    }

    .accountpage__wishlist-card {
        width: 35%;
    }
    .accountpage__wishlist.white-button{
        
    }
   



}

/* tablet */
@media (min-width:641px) and (max-width: 1024px) {
    .accountpage__profile-cards {
        flex-direction: column;
    }

    .accountpage__wishlist-container {
        width: 100%;
    }

    .accountpage__card {
        width: 60vw;
    }

    div.accountpage__tablist {
        font-size: 24px;
    }

    div.accountpage__input {
        --Input-minHeight: 45px;
    }

    .accountpage label {
        font-size: 21px;
    }

    h5.accountpage__profile_title {
        font-size: 24px;
        font-weight: 500;
    }

    button.accountpage__button {
        font-size: 20px;
    }

    div.accountpage__chip {
        font-size: 18px;
    }

    .accountpage__wishlist-card {
        width: 25%;
    }
    div.info {
        display: grid;
        grid-template-columns: repeat(2, minmax(80px, 1fr));
        gap: 1.5;
    }
}

/* pc */
@media (min-width: 1025px) {
    .accountpage__profile {
        flex-direction: column;
    }

    .accountpage__card {
        width: 50%;
    }

    .accountpage__profile-cards {
        flex-direction: row;
    }

    .accountpage__greeting {
        flex-direction: row;
    }


    h5.accountpage__profile_title {
        /* font-size: 21px; */
        font-weight: 500;
    }

    button.accountpage__button {
        font-size: 16px;

    }

    .accountpage__wishlist-container {
        width: 80%;

    }

    .accountpage__wishlist-card {
        width: 20%;
    }
    div.info {
        display: grid;
        grid-template-columns: repeat(2, minmax(80px, 1fr));
        gap: 1.5;
    }


}