/* pc */
@media (min-width: 1025px) {
    button.white-button {
        font-size: 20px;
    }
}

button.white-button {
    color: black;
    background-color: transparent;
    border: 1px solid black;
    margin: 15px;
    font-weight: 800;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
}

button.white-button:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid white;
}

/* tablet + mobile */
@media (max-width: 1024px) {
    .homepage__dark-box button.white-button {
        color: white;
        border: 1px solid white;
    }

    button.white-button {
        font-size: 16px;
    }

}