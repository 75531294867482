/* pc */
@media (min-width: 1025px) {
    .products {
        width: 80%;
    }
}

/* mobile + tablet */
@media (max-width: 1024px) {
    .products {
        width: 100%;
    }

}


.products__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    margin-top: 20px;
}