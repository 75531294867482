/* pc */
@media (min-width: 1025px) {}

/* tablet */
@media (min-width:641px) and (max-width: 1024px) {
    .drawerMenu {
        width: 50vw;
    }

    h2.drawerMenu__category {}

    h3.drawerMenu__type {}
}

/* mobile */
@media (max-width: 640px) {
    .drawerMenu {
        width: 80vw;
    }

    h2.drawerMenu__category {
        font-size: large;
    }

    h3.drawerMenu__type {
        /* font-size: medium; */
    }

}

/* mobile + tablet */
@media (max-width: 1024px) {

    h2.drawerMenu__category {
        text-transform: uppercase;
        margin: 0;
    }

    h3.drawerMenu__type {
        text-transform: lowercase;
        margin: 0 0 15px 0;

    }

}