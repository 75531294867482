.gallery-container {
    display: flex;
    gap: 8px;
}

.image-wrapper {
    position: relative;
}

.image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
}

.more-images {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: relative;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.more-images::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 4px;
}

.more-images .overlay {
    position: absolute;
    font-size: 16px;
    color: black;
    z-index: 1;
    /* Розміщення тексту поверх псевдоелементу */
}


  