@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Geologica:wght@100..900&family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&family=Cormorant+Infant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');


/* tablet */
@media (min-width:641px) and (max-width: 1024px) {
    .homepage__image--mb {
        width: 100vw;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        opacity: 0.8;
    }

    p.homepage__quote {
        font-size: 35px;
    }
}

/* mobile */
@media (max-width:640px) {
    .homepage__image--mb {
        height: 100%;
        opacity: 0.8;
    }

    p.homepage__quote {
        font-size: 25px;
    }
}

/* tablet + mobile */
@media (max-width: 1024px) {
    .homepage__section1 {
        width: 100vw;
        height: 80vh;

    }

    .homepage__image--pc {
        display: none;
    }

    .homepage__dark-box {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.3);
        justify-content: center;
        color: white;
    }

    .homepage__gradient-white {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20vh;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #F6F6F6 100%)
    }
}

/* pc */
@media (min-width: 1025px) {
    .homepage__section1 {
        width: 100vw;
    }

    .homepage__image--pc {
        width: 100%;
    }

    .homepage__image--mb {
        display: none;
    }

    .homepage__dark-box {
        background-color: none;
        justify-content: flex-start;
        color: black;
        margin: 20px;
    }

    .homepage__gradient-white {
        display: none;
    }

    p.homepage__quote {
        font-size: 35px;
    }

    .homepage__category-title {
        display: none;
    }
}

.homepage {
    width: 100vw;
}

.homepage__section1 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.homepage__dark-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

p.homepage__quote {
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    width: 95%;
    text-align: center;
    font-style: italic;
}



p.homepage__category-title {
    position: relative;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    margin-top: -50px;
    z-index: 999;
}