/* mobile */
@media (max-width:640px) {
    .product-card {
        width: 45%;
    }

    h3.product-card__card-name,
    p.product-card__card-price {
        font-size: 14px;
    }

    p.product-card__card-color {
        font-size: 12px;
    }

}

/* tablet */
@media (min-width:641px) and (max-width: 1024px) {
    .product-card {
        width: 25%;
    }

    h3.product-card__card-name,
    p.product-card__card-price {
        font-size: 18px;
    }

    p.product-card__card-color {
        font-size: 14px;
    }
}

/* pc */
@media (min-width: 1025px) {
    .product-card {
        width: 20%;
    }

    h3.product-card__card-name,
    p.product-card__card-price {
        font-size: 18px;
    }

    p.product-card__card-color {
        font-size: 14px;
    }
}

.product-card__card-content {
    margin: 8px 0;
}

h3.product-card__card-name {
    font-weight: 600;
}

p.product-card__card-color {
    font-weight: 300;
}

p.product-card__card-price {
    font-weight: 500;
}

div.product-card__card-cashback {
    font-weight: 400;

}