p.checkout__title {
    font-weight: 600;
    font-size: 20px;
}

.checkout__main {
    display: flex;
    gap: 20px;
    width: "100%";
    justify-content: center;
}

div.checkout__info {
    display: grid;
    grid-template-columns: repeat(2, minmax(80px, 1fr));
    gap: 1.5;
}

div.checkout__orderContent {
    display: flex;
    flex-direction: column;
    padding: 0;
}



/* pc */
@media (min-width: 1025px) {
    .checkout__main {
        flex-direction: row;
        align-items: flex-start;
    }

    .checkout__first {
        width: 60%;
    }

    .checkout__post img{
        height: 40px;
    }

    .checkout__order {
        width: 40%;
    }
}

/* mobile */
@media (max-width: 640px) {
    .checkout__main {
        flex-direction: column;
        align-items: center;

    }

    .checkout__first {
        width: 95%;
    }

    .checkout__info {}

    .checkout__form {
        grid-column: 1/-1;
    }

    .checkout__post img {
        height: 30px;
    }

    .checkout__order {
        width: 95%;
    }
}

@media (min-width:641px) and (max-width: 1024px) {
    .checkout__main {
        flex-direction: column;
        align-items: center;

    }

    .checkout__first {
        width: 95%;
    }

    .checkout__order {
        width: 95%;
    }

    .checkout__post img {
        height: 50px;
    }
}