/* mobile */
@media (max-width: 640px) {
    .completeOrderPage img {
        width: 80%;
    }


}

/* tablet */
@media (min-width:641px) and (max-width: 1024px) {
    .completeOrderPage img {
        width: 50%;
    }

    .completeOrderPage h4 {
        font-size: 32px;
    }
}

@media (min-width: 1025px) {
    .completeOrderPage img {
        width: 18%;
    }
}

.completeOrderPage {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.completeOrderPage div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}